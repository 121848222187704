<template>
  <NuxtLayout name="default">
    <div>
      <div class="background-patterns">
        <div
          class="flex items-center gap-y-[1.938rem] lg:gap-y-0 flex-col lg:min-h-screen lg:flex-row gap-x-8 container pt-[5.875rem] sm:pt-40 pb-10 xl:py-[12.875rem] 2xl:min-h-[900px]"
        >
          <div class="flex-1">
            <h1
              class="text-[1.5rem] lg:text-[3.5rem] text-center lg:text-left heading-text font-heading font-bold leading-[2.3rem] md:leading-[4.025rem] mb-3 lg:mb-[1.5rem] text-header"
            >
              Calculate the Price
            </h1>
            <p
              class="text-description text-center lg:text-left lg:text-content-5 leading-[1.313rem] lg:leading-[1.875rem] text-[0.875rme] lg:text-xl font-light"
            >
              Untitled is a next-gen financial technology
              <br class="hidden lg:block" />
              company in the process of reinventing banking.
            </p>
          </div>
          <div
            class="card-shadow bg-white flex-grow flex-1 w-full xl:max-w-[37.5rem] rounded-2xl h-full"
          >
            <div
              class="h-[3.25rem] flex items-center w-full xl:w-[30.75rem] rounded-tl-2xl overflow-hidden relative"
            >
              <div class="w-full xl:w-[10.625rem] h-full relative">
                <nuxt-link
                  v-slot="{ isExactActive }"
                  to="/rates"
                >
                  <span
                    class="flex items-center justify-center h-full w-full px-1 sm:px-0 text-center transition-colors text-[0.75rem] lg:text-[0.875rem] relative z-10"
                    :class="
                      isExactActive
                        ? 'bg-primary-1 text-white font-medium'
                        : 'bg-orange-light text-content-5 font-light'
                    "
                    >Crypto Exchange</span
                  >
                </nuxt-link>
              </div>
              <div class="w-full xl:w-[8.75rem] h-full">
                <nuxt-link v-slot="{ isExactActive }" to="/rates/giftcard/sell">
                  <span
                    class="flex items-center justify-center h-full w-full text-center px-2 lg:px-0 transition-colors text-[0.75rem] lg:text-[0.875rem] font-light bg-orange-light"
                    :class="
                      isExactActive
                        ? 'bg-primary-1 text-white font-medium'
                        : 'bg-orange-light text-content-5 font-light'
                    "
                  >
                    Gift Card Sell</span
                  >
                </nuxt-link>
              </div>
              <nuxt-link
                class="w-full xl:w-[8.75rem] h-full relative block"
                v-slot="{ isExactActive }"
                to="/rates/giftcard/buy"
              >
                <div class="flex items-center justify-center h-full">
                  <div
                    :class="
                      isExactActive
                        ? 'bg-primary-1 text-white font-medium'
                        : 'bg-orange-light text-content-5 font-light'
                    "
                    class="absolute inset-0 bg-orange-light clip-right-slant hidden xl:block"
                  ></div>
                  <p
                    :class="
                      isExactActive
                        ? 'bg-primary-1 text-white font-medium'
                        : 'bg-orange-light text-content-5 font-light'
                    "
                    class="flex items-center justify-center rounded-tr-2xl px-2 lg:px-0 xl:bg-transparent h-full w-full text-center transition-colors text-[0.75rem] lg:text-[0.875rem] font-light text-content-5 relative z-10"
                  >
                    Gift Card Buy
                  </p>
                </div>
              </nuxt-link>
            </div>
            <slot></slot>
          </div>
        </div>
      </div>

      <div class="bg-white py-8 lg:px-[7.5rem] container max-w-6xl">
        <div>
          <div>
            <div class="flex flex-col xxs:flex-row items-center xxs:justify-between w-full mb-8">
            <h3 class="text-header text-[1.25rem] lg:text-[1.5rem] font-semibold leading-9 w-full xxs:w-fit">
              Market
            </h3>

            <div class="relative w-full mt-4 xxs:mt-0 xxs:w-fit lg:w-[18.75rem]">
              <label for="crypto-asset" class="xxs:absolute inset-y-0 left-3 flex items-center cursor-pointer">
                <IconSearch
                  class="transform -translate-x-1"
                />
              </label>
              <input
                class="w-full xxs:w-8 lg:w-[18.75rem] transition-all duration-300 ease-in-out rounded-xl py-4 pr-4 h-[2.813rem] border border-neutral-3 focus:border-neutral-3 focus:outline-none focus:ring-0 text-[0.875rem] placeholder:text-[0.875rem] placeholder:text-content-3 pl-12 focus:w-full"
                placeholder="Search"
                type="search"
                v-model="searchQuery"
                autocomplete="off"
                name="assets"
                id="crypto-asset"
              />
            </div>
          </div>
          </div>

          <div class="crypto-assests-table bg-white pb-6">
            <div
              v-if="isLoading"
              class="py-24 lg:pr-[1.5rem] lg:pl-[3rem] flex items-center justify-center"
            >
              <IconSpinner class="text-primary-2" />
            </div>

            <table
              v-else-if="
                !isLoading && filterList().length > 0
              "
              class="table-auto w-full"
            >
              <thead class="h-[4.5rem] rounded-[0.75rem]">
                <tr
                  class="rounded-[0.75rem] text-left bg-gray-3 text-xs xs:text-[0.938rem] lg:text-lg"
                >
                  <th>Crypto Assets</th>
                  <th class="!text-right sm:!text-left">Price</th>
                  <th class="hidden sm:table-cell">24H CHANGE</th>
                </tr>
              </thead>
              <transition-group name="fade" tag="tbody">
                <tr
                  v-for="prices in filterList()"
                  class="border-t border-neutral-4"
                  :key="prices.asset.code"
                >
                  <td
                    class="pt-[0.75rem] lg:pr-[1.5rem] pb-[0.75rem] lg:pl-[3rem] h-[5rem]"
                  >
                    <div
                      class="flex items-center gap-x-[0.600rem] xs:gap-x-[0.769rem] lg:gap-x-[1.002rem]"
                    >
                      <img
                        class="w-[1.89rem] h-[1.89rem] xs:w-[2.188rem] xs:h-[2.188rem] lg:w-[2.5rem] lg:h-[2.5rem] rounded-full"
                        :src="prices?.asset?.imageUrl"
                        :alt="prices?.asset?.code"
                        loading="lazy"
                        @error="fallback"
                      />
                      <div class="flex items-center gap-x-1">
                        <p class="font-bold text-xs xs:text-[0.875rem] lg:text-base">
                          {{ prices?.asset?.name }}
                        </p>
                        <span
                          class="uppercase text-content-3 text-xs xs:text-[0.875rem] hidden sm:block"
                          >{{ prices?.asset?.code }}</span
                        >
                      </div>
                    </div>
                  </td>
                  <td
                    class="pt-[0.75rem] lg:pr-[1.5rem] pb-[0.75rem] !text-right sm:!text-left lg:pl-[3rem] h-[5rem] text-content-3 lg:text-body text-xs xs:text-[0.875rem] lg:text-base"
                  >
                    <span>{{ prices?.asset?.symbol }} {{ prices?.price }}</span>
                    <span
                      class="flex items-center gap-x-[0.313rem] justify-end sm:hidden text-right"
                    >
                      <svg
                        v-if="
                          assetPriceIncreaseOrDecrease(
                            prices?.changePercent24Hrs
                          ).amount !== 0
                        "
                        :class="[
                          assetPriceIncreaseOrDecrease(
                            prices?.changePercent24Hrs
                          ).isNegative
                            ? ''
                            : 'transform rotate-180',
                        ]"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.5"
                        height="6.25"
                        viewBox="0 0 16 9"
                        fill="none"
                      >
                        <path
                          d="M14.25 1.50015L8 7.75015L1.75 1.50015"
                          :stroke="
                            assetPriceIncreaseOrDecrease(
                              prices?.changePercent24Hrs
                            ).isNegative
                              ? '#F75555'
                              : '#22C55E'
                          "
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        width="16"
                        height="19"
                        stroke-width="2"
                        stroke="currentColor"
                        class="size-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.499 8.248h15m-15 7.501h15"
                        />
                      </svg>
                      <p
                        :class="[
                          assetPriceIncreaseOrDecrease(
                            prices?.changePercent24Hrs
                          ).isNegative
                            ? 'text-[#F75555]'
                            : assetPriceIncreaseOrDecrease(
                                prices?.changePercent24Hrs
                              ).amount === 0
                            ? 'text-content-3'
                            : 'text-[#22C55E]',
                        ]"
                      >
                        {{
                          assetPriceIncreaseOrDecrease(
                            prices?.changePercent24Hrs
                          ).amount
                        }}%
                      </p>
                    </span>
                  </td>
                  <td
                    class="hidden pt-[0.75rem] lg:pr-[1.5rem] pb-[0.75rem] lg:pl-[3rem] h-[5rem] text-description sm:flex items-center gap-x-[0.547rem]"
                  >
                    <svg
                      v-if="
                        assetPriceIncreaseOrDecrease(prices?.changePercent24Hrs)
                          .amount !== 0
                      "
                      :class="[
                        assetPriceIncreaseOrDecrease(prices?.changePercent24Hrs)
                          .isNegative
                          ? ''
                          : 'transform rotate-180',
                      ]"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="9"
                      viewBox="0 0 16 9"
                      fill="none"
                    >
                      <path
                        d="M14.25 1.50015L8 7.75015L1.75 1.50015"
                        :stroke="
                          assetPriceIncreaseOrDecrease(
                            prices?.changePercent24Hrs
                          ).isNegative
                            ? '#F75555'
                            : '#22C55E'
                        "
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      width="16"
                      height="19"
                      stroke-width="2"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.499 8.248h15m-15 7.501h15"
                      />
                    </svg>

                    <p
                      :class="[
                        assetPriceIncreaseOrDecrease(prices?.changePercent24Hrs)
                          .isNegative
                          ? 'text-[#F75555]'
                          : assetPriceIncreaseOrDecrease(
                              prices?.changePercent24Hrs
                            ).amount === 0
                          ? 'text-content-3'
                          : 'text-[#22C55E]',
                      ]"
                    >
                      {{
                        assetPriceIncreaseOrDecrease(prices?.changePercent24Hrs)
                          .amount
                      }}%
                    </p>
                  </td>
                </tr>
              </transition-group>
            </table>

            <div
              v-if="
                filterList().length <= 0 && !isLoading
              "
              class="items-center text-center w-full py-8"
            >
              <p>No data available</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
import * as Ably from "ably";
import {
  MarketPricesData,
  MarketPricesResponse,
  MarketPrice,
} from "types/crypto-exchange";
import CryptoExchangeService from "~/services/crypto-exchange.service";

const ABLY_KEY = import.meta.env.VITE_APP_ABLY_CRYPTO_EXCHANGE_KEY;
const marketPrices = ref<MarketPricesData[]>([]);
const tableMarketPrices = ref<MarketPrice[]>([]);
const searchQuery = ref<string | null>(null);

let ably: Ably.Realtime | null = null;
let channel: Ably.Types.RealtimeChannelCallbacks | null = null;

async function connectToAblyToGetMarketRates() {
  ably = new Ably.Realtime(ABLY_KEY);
  channel = ably.channels.get("exchange-rates");

  channel.subscribe((message: { data: MarketPricesData }) => {
    if (message.data && message.data.marketPrices) {
      tableMarketPrices.value = message.data.marketPrices;
    }
  });
}

const { isLoading, fetch: hydrate } = useFetch(
  async () => {
    const response: MarketPricesResponse =
    await CryptoExchangeService.getMarketRates();
    return response.data;
  },
  {
    onSuccess: (data) => {
      marketPrices.value = data.data.marketPrices;
      tableMarketPrices.value = data.data.marketPrices;
    },
  }
);
hydrate();

provide("marketPrices", {
  fetchingRates: isLoading,
  prices: marketPrices,
});

function fallback(element: Event) {
  const imgElement = element.target as HTMLImageElement;
  if (imgElement) imgElement.src = "/logo-icon.png";
}

function assetPriceIncreaseOrDecrease(number: number) {
  const amount = Number(number.toFixed(2));
  if (amount < 0) {
    return {
      isNegative: 1,
      amount,
    };
  } else if (amount > 0) {
    return {
      isNegative: 0,
      amount,
    };
  } else {
    return {
      isNegative: 0,
      amount: 0,
    };
  }
}

function filterList() {
  if (!searchQuery.value) {
    return tableMarketPrices.value;
  }
  const lowercaseQuery = searchQuery.value.toLowerCase();
  return tableMarketPrices.value.filter((price) =>
    price.asset.name.toLowerCase().includes(lowercaseQuery)
  );
}

onMounted(() => {
  connectToAblyToGetMarketRates();
});

onUnmounted(() => {
  if (channel) {
    channel.unsubscribe();
  }
  if (ably) {
    ably.close();
  }
});

useHead({
  meta: [
    {
      name: "viewport",
      content:
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=contain",
    },
  ],
});
</script>

<style>
.card-shadow {
  box-shadow: 0px 4px 24px 0px #1515151a;
}

.crypto-assests-table {
  box-shadow: 0px 4px 80px 0px #a6a9b91a;
  padding: 0px, 0px, 24px, 0px;
  border-radius: 16px;
}

td,
th {
  text-align: left;
}

thead th {
  width: 362.67px;
  padding: 0.813rem;
}

td {
  padding: 0.813rem;
}

th:first-child {
  border-top-left-radius: 15px;
}

th:last-child {
  border-top-right-radius: 15px;
}

td:first-child {
  border-bottom-left-radius: 15px;
}

td:last-child {
  border-bottom-right-radius: 15px;
}

.background-patterns {
  background: url("~/assets/images/backgroundpattern-mobile.svg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  width: 100%;
  min-height: 100%;
}

@media (min-width: 700px) {
  .background-patterns {
    background: url("~/assets/images/backgroundpattern.svg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    width: 100%;
  }

  thead th {
    width: 362.67px;
    padding: 0.813rem 3rem;
  }
}

.clip-left-round {
  clip-path: polygon(
    0 0,
    calc(100% - 0.75rem) 0,
    100% 50%,
    calc(100% - 0.75rem) 100%,
    0 100%,
    0 0
  );
}

.clip-right-slant {
  clip-path: polygon(0 0, 100% 0, calc(100% - 1rem) 100%, 0 100%, 0 0);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>

<!--    <div class="flex items-center justify-between px-[2.625rem] pt-6">
             <DataPaginator
          :current-page="1"
          :items-per-page="10"
          :total-items="32"
        />
        <div class="flex items-center gap-x-4">
          <label for="itemsPerPage" class="text-content-3 text-sm"
            >Show result:</label
          >
          <SelectDropdown
            id="itemsPerPage"
            v-model="itemsPerPage"
            class="w-[4.25rem]"
            :options="[10]"
            placeholder=""
          />
        </div> 
            </div>-->
